import Vue from 'vue'
import App from './App.vue'
import './assets/styles/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueQRCodeComponent from 'vue-qrcode-component'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)
// Register the Vue component
Vue.component('qr-code', VueQRCodeComponent)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner,  } from '@fortawesome/pro-solid-svg-icons'
import { faRoad, faBadgeCheck,  } from '@fortawesome/pro-light-svg-icons'
import { faChargingStation, faTachometerAltFast, faLeaf, faCalendarWeek, faPlus } from '@fortawesome/pro-regular-svg-icons'

library.add(faSpinner, faRoad, faChargingStation, faTachometerAltFast, faLeaf, faCalendarWeek, faBadgeCheck, faPlus)



Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
